// material-ui
import { styled } from '@mui/material/styles'
import LinearProgress from '@mui/material/LinearProgress'

/**

A styled component that renders a fixed-positioned linear progress loader
@returns {JSX.Element} The Loader component
*/

// loader style
const LoaderWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 2001,
  width: '100%',
  '& > * + *': {
    marginTop: theme.spacing(2)
  }
}))

// ==============================|| loader ||============================== //

const Loader = () => (
  <LoaderWrapper>
    <LinearProgress color="primary" className="MuiLinearProgress-barColorPrimary"/>
  </LoaderWrapper>
)

export default Loader
