import React from 'react'
import CGUComponent from './CGUComponent'

const CGUContainer = () => {
  return (
    <CGUComponent/>
  )
}

export default CGUContainer
