import Navbar from '../../navbar/index.js'
import React from 'react'

/**

Header component displaying the navigation bar.
@returns {JSX.Element} The Header component
*/

const Header = () => {
  return (
    <Navbar />
  )
}

export default Header
