export const ROUTES = {
  ROOT: {
    NAME: 'root',
    PATH: '/'
  },
  HOME: {
    NAME: 'home',
    PATH: '/'
  },
  FEATURES: {
    NAME: 'features',
    PATH: '/features'
  },
  CONTACT: {
    NAME: 'contact',
    PATH: '/contact'
  },
  PRIVACY_POLICY: {
    NAME: 'privacy-policy',
    PATH: '/privacy-policy'
  },
  CGU: {
    NAME: 'cgu',
    PATH: '/cgu'
  }
}
