import React from 'react'
import Grid from '@mui/material/Grid'
import { Box } from '@mui/material'
// import JeneeLogo from '../../../../assets/images/svg/Home/LOGOPRINCIPAL_SVG.svg'
import JeneeLogo from 'components/icons/jeneeIcon'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../../constants/routesConst'
import useWindowDimensions from 'hooks/useWindowDimensions'

/**

React component representing the footer section of the website.
@return {JSX.Element}
*/

const Footer = () => {
  const navigate = useNavigate()
  const handleTarificationClick = () => {
    navigate(ROUTES.HOME.PATH)
    setTimeout(() => {
      const element = document.getElementById('pricing')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }, 1000)
  }

  const { width } = useWindowDimensions()
  const handleContactClick = () => {
    navigate(ROUTES.HOME.PATH)
    setTimeout(() => {
      const element = document.getElementById('contact_form')
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: width < 900 ? 'end' : 'start',
          inline: 'nearest'
        })
      }
    }, 1000)
  }

  return (
    <Box className="footer">
      <Grid>
        <Grid item xs={12}>
          <div className="footer__wrap">
            <div className="footer__wrap__logo">
              <JeneeLogo alt="Jenee-logo" style={{ width: '3em' }} />
            </div>
            <div className="footer__wrap__bar" />
            <Grid rowSpacing={3} container spacing={2}>
              <Grid item xs={3}>
                <ul data-cy="footer__wrap" className="footer__wrap__ul">
                  <h3>Suivez-nous</h3>
                  <p>
                    <a
                      href="https://www.linkedin.com/company/jenee-fr/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Linkedin
                    </a>
                  </p>
                </ul>
              </Grid>
              <Grid
                item
                xs={9}
                container
                justifyContent={{ xs: 'center', sm: 'flex-end' }}
                rowSpacing={1}
              >
                <Grid item xs={4} sm={3}>
                  <ul data-cy="footer__wrap">
                    <h3>Get Started</h3>
                    <p>
                      <bouton
                        className="footer__link_button"
                        onClick={handleTarificationClick}
                      >
                        Tarification
                      </bouton>
                    </p>
                  </ul>
                </Grid>
                <Grid item xs={4} sm={3}>
                  <ul data-cy="footer__wrap">
                    <h3>Contactez nous</h3>
                    <p>
                      <a
                        href="mailto:contact@jenee.fr?subject=Contacter l'équipe Jenee"
                        target="_blank"
                        rel="noreferrer"
                      >
                        E-mail
                      </a>
                    </p>
                    <p>
                      <bouton
                        className="footer__link_button"
                        onClick={handleContactClick}
                      >
                        Formulaire
                      </bouton>
                    </p>
                  </ul>
                </Grid>
                <Grid item xs={4} sm={3}>
                  <ul data-cy="footer__wrap">
                    <h3>Legal</h3>
                    <p>
                      <Link to={ROUTES.CGU.PATH}>CGU</Link>
                    </p>

                    <p>
                      <Link to={ROUTES.PRIVACY_POLICY.PATH}>
                        Politique de confidentialté
                      </Link>
                    </p>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
            <div className="footer__copyrigth">© 2023 Jenee</div>
          </div>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Footer
