import React from 'react'
import NotFoundComponent from './NotFoundComponent'

/**

This component is render NotFoundComponent
@param {object} routes - Routes for navigation.
@returns {JSX.Element} - Rendered component.
*/

const NotFoundContainer = () => {
  return <NotFoundComponent />
}

export default NotFoundContainer
