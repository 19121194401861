import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import JeneeLogo from 'components/icons/jeneeIcon'
import { ROUTES } from '../../../constants/routesConst'

/**

Component for the website navigation bar.
@returns {JSX.Element} JSX navigation bar element.
*/

const Navbar = () => {
  // const [displayMenu, setDisplayMenu] = useState(false)

  const { pathname } = useLocation()

  const handleTarificationClick = () => {
    const element = document.getElementById('pricing')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleContactClick = () => {
    const element = document.getElementById('contact_form')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <div className="navbar">
      <div className="navbar__container">
        <Link to={ROUTES.HOME.PATH}>
          <JeneeLogo
            alt="jenee-logo"
            className="navbar__container__img"
            sx={{ width: { xs: '4rem' }, height: { xs: '4rem' } }}
          />
        </Link>
        { pathname === '/'
          ? (
            <div className="navbar__container__navigation">
              <ul>
                <li className="navbar__container__navigation__item">
                  <button onClick={handleTarificationClick}>Jenee</button>
                </li>
                <li className="navbar__container__navigation__item">
                  <button onClick={handleTarificationClick}>Tarification</button>
                </li>
                <li className="navbar__container__navigation__item">
                  <button onClick={handleContactClick}>Contact</button>
                </li>
                {/* <li
                className="navbar__container__navigation__item--active"
                onClick={() => setDisplayMenu(!displayMenu)}
              >
                Menu
              </li> */}
              </ul>
            </div>
          )
          : (
            <></>
          )}
        {/* <div
          className="navbar__container__menu-burger"
          onClick={() => setDisplayMenu(false)}
          style={{ display: displayMenu ? 'block' : 'none' }}
        >
          <ul>
            <JeneeLogo
              alt="jenee-logo"
              className="navbar__container__menu-burger__img"
            />
            <li
              onClick={() => setDisplayMenu(false)}
              className="navbar__container__menu-burger__item"
            >
              <Link aria-label="home-link" to={ROUTES.HOME.PATH}>
                Home
              </Link>
            </li>
            <li
              onClick={() => setDisplayMenu(false)}
              className="navbar__container__menu-burger__item"
            >
              <Link aria-label="features-link" to={ROUTES.FEATURES.PATH}>
                Features
              </Link>
            </li>
            <li
              onClick={() => setDisplayMenu(false)}
              className="navbar__container__menu-burger__item"
            >
              <Link aria-label="contact-link" to={ROUTES.CONTACT.PATH}>
                Contact
              </Link>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  )
}

export default Navbar
