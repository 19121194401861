import React, { useMemo } from 'react'
import { SvgIcon } from '@mui/material'
import { ReactComponent as JeneeSvg } from './JeneeIcon.svg'
import { ReactComponent as JeneePurpleLogo } from 'assets/images/svg/Home/LOGO_JENEE_DEGARDE.svg'
import { ReactComponent as JeneeGreenLogo } from 'assets/images/svg/Home/LogoVert.svg'
import { ReactComponent as JeneeBlueLogo } from 'assets/images/svg/Home/Logo_bleu_.svg'

const JeneeIcon = ({ color = 'white', ...props }) => {
  const icon = useMemo(() => {
    if (color === 'purple') {
      return JeneePurpleLogo
    }
    if (color === 'green') {
      return JeneeGreenLogo
    }
    if (color === 'blue') {
      return JeneeBlueLogo
    }
    return JeneeSvg
  }, [color])
  return (
    <SvgIcon
      sx={{ width: '5em', height: '5em' }}
      component={icon}
      inheritViewBox
      {...props}
    />
  )
}

export default JeneeIcon
