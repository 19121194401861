import { Grid } from '@mui/material'
import React from 'react'

const CGUComponent = () => {
  return (
    <Grid container className="cgu">
      <Grid item xs={12}>
        <h2>CONDITIONS GÉNÉRALES D'UTILISATION</h2>
        <div className="cgu__header">
          <h1>CONDITIONS GÉNÉRALES D'UTILISATIONS</h1>
          <span>Document mis à jour le 17/03/2023</span>
        </div>
        <h4>Préambule</h4>
        <p>Jenee est une plate-forme web, disponible à l’adresse https://jenee.fr (le « Site »).</p>
        <p>Ce Site est édité par Fiestapp, SAS au capital de 1000.0 euros, ayant son siège social situé au 155 Avenue du Maréchal Foch, 92210 Saint-Cloud, France et immatriculé au registre du commerce et des sociétés de Saint-Cloud sous le numéro d’identification 948105184, (ci-après « l’Editeur »). Jenee est accessibles aux utilisateurs (le ou les « Utilisateurs »).</p>
        <p>Jenee est hébergé par Hostinger, dont le siège social est situé au 61 Lord Byron, Limassol, Chypre. Cet hébergeur peut être contacté à cette adresse électronique : sales@hostinger.fr.</p>
        <p>L’objet des présentes Conditions Générales d’Utilisation (les « Conditions » ou prises dans leur ensemble, le « Contrat ») est de définir les termes et les conditions régissant les relations entre les Utilisateurs et Fiestapp. En cas de non-respect des termes des présentes Conditions, Fiestapp se réserve le droit de prendre toute mesure de nature à préserver ses intérêts et notamment à en assurer l'exécution.</p>
        <p>L’accès et l’utilisation du site sont soumis à l’acceptation et au respect des présentes Conditions Générales d’Utilisation.</p>
        <p>L’Éditeur se réserve le droit de modifier, à tout moment et sans préavis, le Site et des services ainsi que les présentes CGU, notamment pour s’adapter aux évolutions du Site par la mise à disposition de nouvelles fonctionnalités ou la suppression ou la modification de fonctionnalités existantes.<br/>
        Il est donc conseillé à l’Utilisateur de se référer avant toute navigation à la dernière version des Conditions Générales d’Utilisation accessible à tout moment sur le Site.</p>
        <p>Les présentes Conditions Générales d’Utilisation s’appliquent, en tant que de besoin, à toute déclinaison ou extension du Site sur les réseaux sociaux et/ou communautaires existants ou à venir.</p>
        <h4>Article 1er : Objet du site</h4>
        <p>Le Site permet à l’Utilisateur d’accéder notamment aux services suivants : Connexion et inscription et oublie de mot de passe à la plate-forme Création de contenu en ligne Administration de contenu Gestion et Statistique de finance et contenu (le ou les « Service(s) ») .</p>
        <p>Fiestapp, selon les présentes Conditions, accorde aux Utilisateurs un droit d’accès limité révocable, non exclusif, non cessible aux Services à titre strictement personnel. Toute utilisation de la plate-forme contraire à sa finalité est strictement interdite et constitue un manquement aux présentes dispositions.</p>
        <h4>Article 2 : Accès au site</h4>
        <p>Pour être éligible au Service, l’Utilisateur peut être une personne physique ou morale. Si l’Utilisateur est une personne physique, il doit avoir atteint l’âge de 18 ans et disposer de sa pleine capacité juridique.
        Fiestapp se réserve le droit de suspendre ou de refuser un accès d’un ou plusieurs Utilisateurs au Site.</p>
        <h4>Article 3 : Gestion du site</h4>
        <p>L’Editeur met en oeuvre des solutions techniques nécessaires afin de permettre un accès continu au site pour l’Utilisateur. Toutefois il se réserve la possibilité de limiter ou suspendre l’accès à tout ou partie du Site à tout moment, et sans préavis. Une telle interruption pourra notamment être rendue nécessaire pour des raisons de maintenances du site ou de son contenu, ou pour toute autre raison jugée nécessaire au bon fonctionnement du site par l’Editeur.</p>
        <p>L’utilisation de la Plateforme requiert une connexion et un navigateur internet. La plateforme est accessible à l’adresse suivante : https://.jenee.fr.</p>
        <p>Tous matériels et logiciels nécessaires à l’accès à la Plateforme et à l’utilisation des Services restent exclusivement à la charge de l’Utilisateur.</p>
        <h4>Article 4 : Contact</h4>
        <p>L’utilisateur a la possibilité de contacter l’Editeur pour tout question ou demande d’information concernant le Site, ou tout signalement de contenu ou d’activités illicites par mail à l’adresse contact@jenee.fr.</p>
        <h4>Article 5 : Utilisation des services de la plateforme</h4>
        <h5>1.1 Obligations des utilisateurs</h5>
        <p>Les Utilisateurs s’interdisent :</p>
        <ol>
          <li>de transmettre, publier, distribuer, enregistrer ou détruire tout matériel, en particulier les contenus de Jenee, en violation des lois ou réglementations en vigueur concernant la collecte, le traitement ou le transfert d'informations personnelles ;</li>
          <li>de diffuser des données, informations, ou contenus à caractère diffamatoire, injurieux, obscène, offensant, violent ou incitant à la violence, ou à caractère politique, raciste ou xénophobe et de manière générale tout contenu qui serait contraire aux lois et règlements en vigueur ou aux bonnes moeurs ;</li>
          <li>de référencer ou créer des liens vers tout contenu ou information disponible depuis les sites de Jenee, sauf accord exprès, préalable et écrit de Fiestapp ;</li>
          <li>d’utiliser des informations, contenus ou toutes données présentes sur le Site afin de proposer un service considéré comme concurrentiel à Jenee ;</li>
          <li>de vendre, échanger ou monnayer des informations, contenus ou données présentes sur la plateforme ou des Service proposés par la Plateforme, sans l’accord expresse et écrit de Fiestapp ;</li>
          <li>de pratiquer de l’ingénierie inversée (Reverse Engineering), décompiler, désassembler, déchiffrer ou autrement tenter d’obtenir le code source en relation avec toute propriété intellectuelle sous-jacente utilisée pour fournir tout ou partie des Services ;</li>
          <li>d’utiliser des logiciels ou appareils manuels ou automates, robots de codage ou autres moyens pour accéder, explorer, extraire ou indexer toute page du Site ;</li>
          <li>de mettre en danger ou essayer de mettre en danger la sécurité numérique de Jenee. Cela comprend les tentatives de contrôler, scanner ou tester la vulnérabilité du système ou réseau ou de violer des mesures de sécurité ou d’authentification sans une autorisation préalable expresse ;</li>
          <li>de contrefaire ou d’utiliser les produits, les logos, les marques ou tout autre élément protégé par les droits de propriété intellectuel de Fiestapp ;</li>
          <li>de simuler l’apparence ou le fonctionnement du Site, en procédant par exemple à un effet miroir ;</li>
          <li>de perturber ou troubler, directement ou indirectement Jenee, ou imposer une charge disproportionnée sur l’infrastructure du Site, ou de tenter de transmettre ou d’activer des virus informatiques via ou sur le Site.</li>
        </ol>
        <p>Il est rappelé que les violations de la sécurité du système ou du réseau peuvent conduire à des poursuites civiles et pénales. Fiestapp vérifie l’absence de telle violation et peut faire appel aux autorités judiciaires pour poursuivre, le cas échéant, des Utilisateurs ayant participé à de telles violations.</p>
        <p>Les Utilisateurs s’engagent à utiliser le Site de manière loyale, conformément à sa finalité et aux dispositions légales, réglementaires, aux présentes Conditions et aux usages en vigueur.</p>
        <h5>1.2 Compte clients des utilisateurs</h5>
        <p>L’Editeur réserve certains services de Jenee aux Utilisateurs ayant procédé à leur inscription sur le site, cela concerne notamment les services payants. Lors de son inscription, l’Utilisateur s’engage à fournir des informations valables et sincères. Il s’engage notamment à fournir à l’Editeur une adresse mail afin que ce dernier puisse lui communiquer des informations.</p>
        <p>Toute information communiquée par mail de l’Editeur à l’Utilisateur sera réputée avoir été lue par ce dernier.</p>
        <p>Tout utilisateur régulièrement inscrit sur le Site pourra demander la suppression de son compte client, l’Editeur s’engage à supprimer les comptes clients pour lesquels il aura reçu une telle demande.</p>
        <p>Les données personnelles des clients sont supprimées au bout de 36 mois sans que le client ne se soit connecté.</p>
        <h5>Article 6 : Propriété intellectuelle</h5>
        <p>L’ensemble du contenu du Site, notamment les designs, textes, graphiques, images, vidéos, informations, logos, icônes-boutons, logiciels, fichiers audio et autres appartient à Fiestapp, lequel est seul titulaire de l’intégralité des droits de propriété intellectuelle y afférents.</p>
        <p>Toute représentation et/ou reproduction et/ou exploitation partielle ou totale des contenus et Services proposés par Jenee, par quelque procédé que ce soit, sans l'autorisation préalable et écrite de Fiestapp, est strictement interdite et serait susceptible de donner lieu à des poursuites judiciaires.</p>
        <h5>Article 7 : Données à caractère personnelles</h5>
        <p>Toutes les données à caractère personnel dont dispose Fiestapp sont recueillies légalement et loyalement selon les modalités de la politique de confidentialité accessible à cette adresse : https://jenee.fr/privacy-policy.</p>
        <p>Ces données sont fournies par les Utilisateurs qui acceptent de manière volontaire et expresse les présentes Conditions autorisant Fiestapp à traiter, divulguer ou transférer ces données à tout tiers cela afin de permettre (i) à l’Utilisateur de profiter pleinement des Services et des fonctions proposés par le Site, (ii) prévenir toute fraude et /ou (iii) à des fins statistiques.</p>
        <p>Les données à caractère personnel sont stockées par Fiestapp en vue de leur traitement dans le cadre de l’utilisation des Services. Elles sont conservées aussi longtemps que nécessaire pour l’apport des Services et fonctions offerts par le Site.</p>
        <p>L’Utilisateur reste toujours propriétaire des informations le concernant qu’il transmet à Fiestapp. Il dispose, conformément à la loi n° 78-17 du 6 janvier 1978 selon sa version consolidée au 24 mars 2020, d’un droit d’accès, de rectification et de suppression des données à caractère personnel le concernant, ainsi que du droit de s’opposer à la communication de ces données à des tiers pour de justes motifs.</p>
        <p>L’Utilisateur pourra exercer ses droits en écrivant à l’adresse électronique suivante : contact@jenee.fr.</p>
        <p>Une réponse à la requête de l’Utilisateur lui sera adressée dans un délai de 30 jours.</p>
        <h5>Article 8 : Responsabilité</h5>
        <p>Il est rappelé que les données publiées par les Utilisateurs et les informations partagées par ces derniers peuvent être captées et exploitées par d’autres Utilisateurs ou des tiers. En ce sens, Fiestapp ne garantit pas le respect de la propriété de ces données, il incombe à l’Utilisateur de prendre l’ensemble des dispositions nécessaires afin que soit préservée la propriété de ses données.</p>
        <p>Fiestapp ne garantit pas le fonctionnement sans interruption ou sans erreur de fonctionnement des Services, en particulier, la responsabilité de Fiestapp ne saurait être engagée en cas d’interruption d’accès à la Plateforme en raison d’opérations de maintenance, de mises à jour ou d’améliorations techniques.</p>
        <p>En tout état de cause, Fiestapp ne saurait en aucune circonstance être responsable au titre des pertes ou dommages indirects ou imprévisibles de l’Utilisateur ou de tout tiers, ce qui inclut notamment tout gain manqué, tout investissement malheureux, inexactitude ou corruption de fichiers ou données, préjudice d’image ou commercial, perte de chiffre d’affaires ou bénéfice, perte de clientèle ou perte de chance lié à quelque titre et sur quelque fondement que ce soit.
        En outre, Fiestapp ne saurait être responsable de tout retard ou inexécution du présent Contrat justifié par un cas de force majeure, telle qu’elle est définie par la jurisprudence des cours et tribunaux français.</p>
        <h5>Article 9 : Convention de preuves</h5>
        <p>Les systèmes et fichiers informatiques font foi dans les rapports entre Fiestapp et l’Utilisateur.</p>
        <p>Ainsi, Fiestapp pourra valablement produire dans le cadre de toute procédure, aux fins de preuve les données, fichiers, programmes, enregistrements ou autres éléments, reçus, émis ou conservés au moyen des systèmes informatiques exploités, sur tous supports numériques ou analogiques, et s’en prévaloir sauf erreur manifeste.</p>
        <h5>Article 10 : Indivisibilité</h5>
        <p>Le fait que l’une quelconque des dispositions du Contrat soit ou devienne illégale ou inapplicable n’affectera en aucune façon la validité ou l’applicabilité des autres stipulations du Contrat.</p>
        <h5>Article 11 : Règlement des différents</h5>
        <p>La conclusion, l’interprétation et la validité du présent Contrat sont régis par la loi française, quel que soit le pays d’origine de l’Utilisateur ou le pays depuis lequel l’Utilisateur accède à Jenee et nonobstant les principes de conflits de lois.</p>
        <p>Dans l’hypothèse où un différend portant sur la validité, l’exécution ou l’interprétation du présent Contrat et serait porté devant les juridictions civiles, il sera soumis à la compétence exclusive des tribunaux français auquel il est fait expressément attribution de compétence, même en cas de référé ou de pluralité de défendeurs.</p>
        <p>L’Utilisateur est informé qu’il peut en tout état de cause recourir à une médiation conventionnelle ou à tout mode alternatif de règlement des différends (conciliation par exemple) en cas de contestation.</p>
        <h5>Article 12 : Durée des conditions générales d’utilisations</h5>
        <p>Les présentes conditions générales d’utilisation sont conclues pour une durée indéterminée, l’Utilisateur est tenu de les respecter à compter du début de son utilisation des Services.</p>
        <p>L’Editeur se réserve la possibilité de modifier ce document à tout moment et sans préavis. Les utilisateurs seront informés de chaque mise à jour du document.</p>
      </Grid>
    </Grid>
  )
}

export default CGUComponent
