import React, { lazy, Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { ROUTES } from '../constants/routesConst'
import Loader from '../components/common/loader/Loader'
import NotFoundContainer from '../components/pages/notFound/NotFoundContainer'
import MainLayout from '../components/layout/mainLayout'
import CustomizedSnackbars from '../components/common/snackbar/SnackBar'
import PrivacyPolicyContainer from 'components/pages/privacyPolicy/PrivacyPolicyContainer'
import CGUContainer from 'components/pages/cgu/CGUContainer'
import ScrollToTop from './scrollToTop'

/**

Component that sets up the routing for the application.
Uses React Router and lazy loading for code splitting.
@component
@return {JSX.Element} JSX.Element that renders the BrowserRouter and Routes for the application.
*/

const HomeContainer = lazy(() =>
  import('../components/pages/home/HomeContainer')
)

const Rooter = () => {
  const [loader, setLoader] = useState(false)
  const [open, setOpen] = useState(false)
  const [snackbar, setSnackbar] = useState({ severity: '', message: '' })

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false)
      }, 2000)
    }
  }, [open])
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <ScrollToTop />
        <Routes>
          <Route exact path={ROUTES.ROOT.PATH} element={<MainLayout />}>
            <Route
              index
              path={ROUTES.HOME.PATH}
              element={
                <HomeContainer
                  routes={ROUTES}
                  setLoader={setLoader}
                  setOpen={setOpen}
                  setSnackbar={setSnackbar}
                />
              }
            />
            <Route
              exact
              path={ROUTES.PRIVACY_POLICY.PATH}
              element={<PrivacyPolicyContainer />}
            />
            <Route exact path={ROUTES.CGU.PATH} element={<CGUContainer />} />
          </Route>
          <Route path={'*'} element={<NotFoundContainer />} />
        </Routes>
        <CustomizedSnackbars
          open={open}
          setOpen={setOpen}
          severity={snackbar.severity}
          message={snackbar.message}
        />
        {loader && <Loader />}
      </Suspense>
    </BrowserRouter>
  )
}

export default Rooter
