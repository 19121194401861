import React from 'react'
import { Outlet } from 'react-router-dom'
// project import
import Footer from './footer'
import Header from './header'
import { Box, Grid } from '@mui/material'

/**

The main layout component of the application, containing the header, the main content area
and the footer.
@returns {JSX.Element} The main layout component
*/

const MainLayout = () => {
  return (
    <Box>
      <Grid>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12}>
          <Box component="main">
            <Outlet />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </Box>
  )
}

export default MainLayout
