import { Grid } from '@mui/material'
import React from 'react'

const PrivacyPolicyComponent = () => {
  return (
    <Grid container className="privacy-policy">
      <Grid item xs={12}>
        <h2>POLITIQUE DE CONFIDENTIALITÉ</h2>
        <div className="privacy-policy__header">
          <h1>POLITIQUE DE CONFIDENTIALITÉ DE Jenee</h1>
          <span>Document mis à jour le 02/03/2023</span>
        </div>
        <p>Fiestapp (« Fiestapp », « notre », « nous » et « nos ») et nos partenaires respectent votre vie privée.</p>
        <p>Nous vous demandons de bien vouloir lire attentivement la présente politique de confidentialité pour comprendre comment sont collectées, traitées et conservées vos données personnelles lors de votre utilisation du présent site internet Jenee, accessible via l’url https://app.jenee.fr.</p>
        <p>Le terme “données personnelles” désigne toute information se rapportant à une personne physique et permettant de l’identifier, directement ou indirectement, à partir d’une seule donnée ou du croisement d’un ensemble de données.</p>
        <p>L’ensemble des données personnelles collectées sur le présent site internet sont traitées sous la responsabilité de la société Fiestapp, SAS au capital social de 1000 €, immatriculée au Registre du Commerce et des Sociétés de Saint-Cloud sous le numéro 948105184, et ayant son siège social 155 Avenue du Maréchal Foch, 92210 Saint-Cloud, France et dans le respect de la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, dans sa version actuelle, ainsi que le règlement (UE) 2016/679 du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données.</p>
        <p>Au sens de la réglementation applicable aux données personnelles, Fiestapp est donc responsable de traitement.</p>
        <p>Cette politique de confidentialité décrit :</p>
        <ul className="privacy-policy__summary">
          <li>Comment Fiestapp utilise vos données personnelles</li>
          <li>Comment Fiestapp partage vos données personnelles</li>
          <li>Comment Fiestapp protège vos données personnelles</li>
          <li>Où Fiestapp héberge et transfère vos données personnelles</li>
          <li>Comment vous pouvez exercer vos droits relatifs à vos données personnelles</li>
          <li>Les mises à jour de la politique de confidentialité</li>
          <li>Comment nous contacter</li>
        </ul>
        <div className="privacy-policy__bar"></div>
        <h3>I. Comment Fiestapp utilise vos données personnelles</h3>
        <p>Fiestapp peut utiliser vos données personnelles aux fins suivantes :</p>
        <ul className="privacy-policy__personal-data-summary">
          <li>Créer votre compte client sur le présent site internet</li>
          <li>Gérer les commandes de produits et/ou services</li>
          <li>Procéder aux actions nécessaires à la gestion de contrats, factures et suivi de la relation client</li>
          <li>Publier et gérer vos avis et/ou commentaires laissés sur le site internet</li>
          <li>Vous adresser notre newsletter, dans le cas où vous y êtes inscrit</li>
          <li>Répondre à votre demande de contact effectuée à partir de notre site internet</li>
          <li>Etablir un programme de fidélité</li>
          <li>Vous proposer des publicités et un contenu adapté</li>
          <li>Constituer un fichier d’utilisateurs, de prospects et de clients</li>
          <li>Elaborer des statistiques commerciales et de fréquentation</li>
          <li>Gérer les impayés et litiges éventuels</li>
          <li>Respecter nos obligations légales</li>
        </ul>
        <p>Les données collectées sont nécessaires à l’exécution du contrat passé avec Fiestapp lorsque vous utilisez notre site internet afin de commander les produits et/ou services disponibles à la vente sur le site.</p>
        <p>Lorsque vous nous fournissez volontairement des données personnelles, la collecte de vos données personnelles est fondée sur l’intérêt légitime suivant : mieux répondre à vos demandes d’information.</p>
        <p>Le traitement de vos données personnelles afin de vous envoyer notre newsletter est, en revanche, basé uniquement sur votre consentement à recevoir notre newsletter, que vous pouvez retirer à tout moment. Si vous ne consentez pas à l’envoi de la newsletter, veuillez noter que cela ne vous empêchera pas de créer votre compte client et de passer des commandes sur notre site internet.</p>
        <h3>II. Comment Fiestapp partage vos données personnelles</h3>
        <p>Au sein de Fiestapp, et au regard de chaque finalité de traitement, les données personnelles vous concernant sont collectées, traitées et stockées par le personnel habilité de Fiestapp, uniquement dans le cadre de leurs compétences respectives, et notamment par le service client, le département marketing et le département informatique.</p>
        <p>Nous ne partageons pas les données personnelles avec d’autres entreprises, organisations et personnes, à moins que l’une des circonstances suivantes s’applique :</p>
        <ol>
          <li>Le partage avec consentement préalable : après avoir obtenu votre consentement, Fiestapp partagera les informations que vous avez autorisées avec les tiers ou catégories de tiers spécifiques renseignés à l’occasion de la collecte de votre consentement.</li>
          <li>Le partage avec nos prestataires : Fiestapp peut également divulguer vos informations à des entreprises qui fournissent des services pour nous ou en notre nom. Ces prestataires de services comprennent des entreprises qui proposent des services informatiques tels notamment notre hébergeur ou notre prestataire d’envoi d’emails, des services de livraison de nos produits, ou qui proposent des activités marketing pour notre compte. Ces prestataires de services peuvent utiliser vos informations uniquement dans le but de vous fournir des services au nom de Fiestapp.</li>
          <li>En exécution d’une obligation légale, le partage conformément aux lois et à la réglementation : Fiestapp, peut partager vos informations comme le stipulent les lois et la réglementation, afin de résoudre les différends d’ordre juridique, ou comme le stipulent les autorités judiciaires ou administratives en vertu de la loi.</li>
        </ol>
        <p>Fiestapp s’assurera de la légalité de tout partage des données personnelles via des clauses de traitement de données avec les entreprises avec lesquelles vos données personnelles sont partagées, les obligeant à se conformer à cette politique de confidentialité et à prendre les mesures de sécurité et confidentialité appropriées lors du traitement de données à caractère personnel.</p>
        <h3>III. Comment Fiestapp protège vos données personnelles</h3>
        <p>
    Fiestapp attache une grande importance à la sécurité de vos données personnelles et a adopté des pratiques courantes de l'industrie afin de protéger vos données personnelles et d'éviter l'accès non autorisé, la divulgation, l'utilisation, la modification, les dommages ou la perte de ces informations.
        </p>
        <p>
    Nous avons par ailleurs pris les précautions utiles afin de préserver, par notre hébergeur, la sécurité et la confidentialité des données, et notamment empêcher qu’elles ne soient déformées, endommagées ou communiquées à des personnes non autorisées.
        </p>
        <p>
    Fiestapp adopte également les mesures organisationnelles suivantes :
        </p>
        <ol>
          <li>
      Nous adoptons des mesures raisonnables et réalisables afin de veiller à ce que les données personnelles recueillies soient minimes et pertinentes selon ce qui est nécessaire, eu égard aux fins pour lesquelles elles sont traitées.
          </li>
          <li>
      Nous conservons vos données personnelles pendant la durée qui est strictement nécessaire au regard de la finalité du traitement, à moins que la conservation de vos données soit requise ou permise par la loi. À titre d’exemple, nous conservons les données liées à l’exécution de vos commandes pour la durée requise par la loi au titre de conservation des dossiers comptables, à savoir au maximum 10 ans à compter de l’exercice concerné.
          </li>
          <li>
      Nous déployons les mécanismes de contrôle d'accès afin de s'assurer que seul le personnel habilité peut accéder à vos données personnelles.
          </li>
        </ol>
        <p>
    En cas de violation des données personnelles, Fiestapp respectera les exigences légales et réglementaires applicables à la notification des violations de données personnelles auprès des autorités de contrôle compétentes et/ou des personnes concernées.
        </p>
        <h3>IV. Où Fiestapp héberge et transfère vos données personnelles</h3>
        <p>
        Vos données personnelles seront hébergées au sein des infrastructures d’hébergement de notre hébergeur, Hostinger, situé en Chypre.
        </p>
        <h3>V. Comment vous pouvez gérer vos droits relatifs à vos données personnelles</h3>
        <p>Vous disposez d’un droit d’accès, de rectification, d’effacement, de limitation, d’opposition concernant le traitement de vos données personnelles ainsi que du droit de définir des directives relatives au sort de vos données après votre mort et du droit à la portabilité de vos données personnelles.</p>
        <p>La CNIL définit une donnée personnelle comme « toute information se rapportant à une personne physique identifiée ou identifiable. Mais parce qu’elles concernent des personnes, celles-ci doivent en conserver la maîtrise ».</p>
        <p>Vous disposez également d’un droit de recours auprès de la Commission Nationale Informatique et Libertés pour la France et auprès d’une autorité de contrôle compétente pour tout autre Etat membre selon votre résidence habituelle, votre lieu de travail ou le lieu où la violation de vos droits aurait été commise si vous estimez que le traitement de vos données ne respecte pas les textes applicables. Ce recours pourra être exercé sans préjudice de tout autre recours devant une juridiction administrative ou juridictionnelle qui constitue également un droit dont vous disposez.</p>
        <p>Vous pouvez nous contacter à tout moment aux adresses indiquées dans la rubrique ‘’Comment nous contacter’’ ci-dessous afin d’exercer vos droits en matière de données personnelles dans les conditions posées par la réglementation applicable. Vous devez indiquer quel droit vous entendez exercer ainsi que l’ensemble des précisions nécessaires pour que nous puissions répondre à votre demande.</p>
        <p>Ces droits s’exercent dans les conditions posées par la réglementation applicable.</p>
        <ul>
          <li>Le droit d’accès signifie que vous pouvez nous demander à tout moment de vous indiquer si nous traitons des données personnelles vous concernant et, le cas échéant, de vous indiquer quelles sont les données personnelles concernées ainsi que les caractéristiques du ou des traitements effectués.</li>
          <li>Le droit de rectification signifie que vous pouvez nous demander la rectification de vos données personnelles lorsqu’elles sont inexactes. Vous pouvez également demander à ce que vos données personnelles, dès lors qu’incomplètes, soient complétées dans la mesure où cela est pertinent au regard de la finalité du traitement en cause.</li>
          <li>Le droit à l’effacement signifie que vous pouvez demander d’effacer vos données personnelles notamment lorsque :
            <ol>
              <li>Leur conservation n’est plus nécessaire au regard des finalités pour lesquelles elles ont été collectées ;</li>
              <li>Vos données personnelles sont traitées sur le fondement de votre consentement, vous souhaitez retirer ce consentement, et il n’existe pas d'autre fondement juridique susceptible de justifier le traitement ;</li>
              <li>Vous vous êtes opposé au traitement de vos données personnelles et vous souhaitez en conséquence qu’elles soient effacées ;</li>
              <li>Vos données personnelles ont fait l'objet d'un traitement illicite ;</li>
              <li>Vos données personnelles doivent être effacées pour respect une obligation légale qui est prévue soit par le droit de l'Union Européenne soit par le droit français.</li>
            </ol>
          </li>
          <li>Le droit à la limitation signifie que vous pouvez nous demander de procéder à la limitation du traitement de vos données personnelles :
            <ol>
              <li>Lorsque vous contestez l’exactitude de vos données personnelles pendant une durée nous permettant de vérifier l’exactitude de celles-ci ;</li>
              <li>Lorsque suite à un traitement établi comme non conforme, vous préférez la limitation du traitement à l’effacement complet de vos données personnelles ;</li>
              <li>Lorsque nous n’avons plus besoin de vos données personnelles aux fins du traitement mais que celles-ci vous sont encore nécessaires pour la constatation, l’exercice ou la défense de droits en justice ;</li>
              <li>Lorsque vous vous êtes opposé au traitement de vos données personnelles et vous souhaitez une limitation du traitement pendant la durée nous permettant de vérifier si le motif légitime que vous invoquez se justifie.</li>
            </ol>
          </li>
        </ul>
        <p>La limitation du traitement signifie que le traitement de vos données personnelles s’entendra alors du seul stockage de vos données personnelles correspondantes. Nous n’effectuerons alors plus aucune autre opération sur les données personnelles considérées.</p>
        <ul>
          <li>Le droit d’opposition signifie que vous pouvez vous opposer au traitement de vos données personnelles, lorsque ce traitement est fondé sur la poursuite de l’intérêt légitime de Fiestapp. Le droit d’opposition s’exerce sous réserve de justifier d’un motif légitime tenant à votre situation particulière. Nous cesserons alors le traitement en cause sauf s’il existe des motifs légitimes et impérieux en justifiant la poursuite en conformité avec la réglementation applicable.</li>
          <li>Le droit de définir des directives relatives au sort de vos données après votre mort vous permet de faire connaître vos instructions concernant la conservation, l'effacement et la communication de vos données personnelles après votre décès.</li>
          <li>Le droit à la portabilité signifie que vous pouvez nous demander, dans les conditions posées par la réglementation applicable, de recevoir vos données personnelles dans un format structuré, couramment utilisé et lisible par machine, et de vous les transmettre, ou de nous demander de les transmettre directement à un tiers de votre choix lorsque cela est juridiquement et techniquement possible.</li>
        </ul>
        <p>Lorsque nous traitons vos données personnelles sur la base de votre consentement, vous disposez enfin de la faculté de retirer votre consentement à tout moment en vous adressant aux adresses indiquées dans la rubrique ’’Comment nous contacter’’ ou en cliquant sur le lien de désabonnement présent dans chacune de nos communications.</p>
        <p>Toutefois, le retrait de votre consentement ne remet pas en cause la validité du traitement effectué avant ce retrait.</p>
        <h3>VI. Les mises à jour de cette politique de confidentialité</h3>
        <p>Fiestapp se réserve le droit à tout moment de modifier ou mettre à jour, en tout ou en partie, la présente politique de confidentialité, en raison de la modification de la réglementation applicable en matière de protection des données personnelles ou des traitements de données effectués.</p>
        <p>Toute modification substantielle de la politique de confidentialité vous sera notifiée par e-mail lorsque vous nous avez communiqué une adresse mail valide et sera publiée sur le site internet. Nous vous recommandons de prendre régulièrement connaissance de la présente politique de confidentialité afin d’avoir une parfaite connaissance de nos engagements en matière de sécurité et de protection de vos données personnelles.</p>
        <h3>VII. Comment nous contacter</h3>
        <p>Si vous avez des questions, des commentaires ou des suggestions, veuillez nous contacter en visitant la page contactez-nous ou en les soumettant à contact@jenee.fr.</p>
        <p>Ou par courrier postal à 155 Avenue du Maréchal Foch, 92210 Saint-Cloud, France.</p>
        <p>Si vous n'êtes pas satisfait de la réponse apportée par Fiestapp à une demande d’exercice de droits conformément à l’article V ci-dessus ou que vous souhaitez signaler une atteinte à la réglementation applicable en matière de protection des données, vous disposez du droit d’introduire une réclamation auprès de la CNIL par courrier (CNIL - 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07) ou sur son site Internet (www.cnil.fr), ou auprès de l'autorité de protection des données du pays dans lequel vous résidez ou travaillez habituellement.</p>
      </Grid>
    </Grid>
  )
}

export default PrivacyPolicyComponent
