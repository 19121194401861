import React from 'react'
import PrivacyPolicyComponent from './PrivacyPolicyComponent'

const PrivacyPolicyContainer = () => {
  return (
    <PrivacyPolicyComponent />
  )
}

export default PrivacyPolicyContainer
